<template>
	<div class="submit">
		<el-page-header :title=" this.isChinese() ? '确认订单':'Confirm An Order'" @back="goBack"
			content=""></el-page-header>
		<div class="adrss boxss" v-if="userInfo.address">
			<div class="title">{{ $t('mine.pepol.adr') }}</div>
			<div class="phone">{{ userInfo.nickName }} {{ userInfo.phoneNumber }}</div>
			<div class="adr">{{ userInfo.address }}</div>
		</div>
		<div class="adrss boxss" v-else @click="$router.push('/mine/userInfo')">
			<div class="title">{{ $t('mine.pepol.adr') }}</div>
			<div class="notAdrss">
				<span>{{ $t('submitOrder.addAdress') }}</span>
				<i class="el-icon-arrow-right"></i>
			</div>
		</div>
		<div class="goods-list boxss">
			<div class="title">{{ $t('submitOrder.goodsXi') }}</div>
			<div class="list" v-for="(item, index) of shoppingCartList" :key="index">
				<img class="pic" :src="item.cover" alt="">
				<div class="productDatil">
					<div class="name dis">
						<span>{{ isChinese() ? item.name:item.nameEng }}</span>
						<span>¥{{item.price}}</span>
					</div>
					<div class="sku">
						<div>
							<!-- <span>颜色：</span> 紫色红色蓝色 -->
							{{ isChinese() ? item.keyName:item.keyNameEng }}
						</div>
						<span>x{{item.number}}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="boxss totoy">
			<div>
				<span>{{ $t('submitOrder.goodsPic') }}：</span>
				<span>¥{{totalPic}}</span>
			</div>
			<div>
				<span>{{ $t('submitOrder.peisong') }}：</span>
				<span>¥{{freight}}</span>
			</div>
		</div>
		<div class="after">
			<div class="right">
				<div class="totle">{{ $t('submitOrder.heji') }}： ¥{{freight+totalPic}}</div>
				<div class="button" @click="submitPay">
					{{ $t('submitOrder.jieshuan') }}
				</div>
			</div>
		</div>
		<el-dialog title=" " :visible.sync="payDialogVisible" width="30%" :before-close="handleClose">
			<div class="pay">
				<div class="title">{{ $t('submitOrder.yingfujine') }}</div>
				<div class="total">¥{{ freight + totalPic }}</div>
				<div class="type">
					<div class="wx">
						<div>
							<img :src="wchatPayPic" alt="">
						</div>
						<div>
							<img src="../../../public/imgs/mine/wxpay.png" alt="">
							{{ $t('submitOrder.wahctQce') }}
						</div>
					</div>

				</div>
				<el-button type="primary" style="padding: 15px 20px;margin: auto;"
					@click="getOrderStatus">{{isChinese() ?  '支付成功':'Payment Success'}}</el-button>
			</div>
		</el-dialog>
		<el-dialog :title="isChinese() ?'支付':'Choice of payment method'" :visible.sync="dialogVisible" width="500px"
			:before-close="handleClose" center>
			<div class="fabumainboxpay">
				<div class="fabumainboxpay-titlef">
					<p class="fabumainboxpay-p">￥{{ freight + totalPic }} </p>
				</div>
				<!-- <div class="fabumainboxpay-pay">
                    <div class="fabumainboxpay-pay-line" @click="selcetPayType(item, index)"
                        v-for="(item, index) of payTypeList" :key="index">
                        <div class="flexbox">
                            <img :src="item.img" alt="" class="wximg">
                            <div>{{item.text}}</div>
                        </div>
                        <img :src="item.ico" class="chooseimg">
                    </div>
                </div> -->
			</div>
			<div slot="footer" class="dialog-footer-ggpay">
				<el-button @click="cancel">{{$t('button.quxiao')}}</el-button>
				<el-button type="primary" @click.stop="topay">{{$t('button.queding')}}</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex'
	import {
		submitOrder,
		getOrderStatus
	} from '../../api/main'
	// import base from '@/config/env'
	export default {
		data() {
			return {
				dialogVisible: false,
				shoppingCartList: [], //购物车商品表
				totalPic: 0, //商品总价
				freight: 0, //运费
				totalNumber: 0, //全部商品数量
				wchatPayPic: '',
				alipayPic: '',

				payDialogVisible: false,
				orderId: '', //生成的支付订单
				payTypeList: [{
						text: '微信支付',
						img: require('../../../public/imgs/mine/wxpay.png'),
						ico: require('../../../public/imgs/yes.png'),
						type: 1
					},
					{
						text: '支付宝支付',
						img: require('../../../public/imgs/mine/alipay.png'),
						ico: require('../../../public/imgs/ofOn.png'),
						type: 2
					}
				],
				payType: 1
			}
		},
		mounted() {
			console.log(JSON.parse(this.$route.params.cartList))
			this.shoppingCartList = JSON.parse(this.$route.params.cartList)
			this.updateTotalPic();
			const popup = document.createElement('script')
			popup.setAttribute('src', 'https://js.paystack.co/v2/inline.js')
			popup.async = true
			document.head.appendChild(popup)
		},
		computed: {
			...mapState(['userInfo'])
		},
		methods: {
			selcetPayType(item, index) {
				this.payTypeList.forEach((e, i) => {
					if (i == index) {
						e.ico = require('../../../public/imgs/yes.png')
					} else {
						e.ico = require('../../../public/imgs/ofOn.png')
					}

				})
				this.payType = item.type
			},
			cancel() {
				this.centerDialogVisible = false
				this.dialogVisible = false
			},
			submitPay() { //提交订单
				if (!this.userInfo.address) {
					this.$message({
						type: 'warning',
						message: this.isChinese() ? '请先添加地址' : 'Please add the address first'
					});
					return
				}
				let shoppingCartIds = this.shoppingCartList.map((item) => {
					return item.id
				})
				if (this.orderId) { //如果有值说明已经下单用户点击取消了在次不用下单直接支付
					this.payOrderWeb()
					return
				}
				submitOrder({
					totalNumber: this.totalNumber,
					goodsPrice: this.totalPic,
					freight: this.freight,
					totalPrice: this.totalPic + this.freight,
					shoppingCartIds: shoppingCartIds.join(',')
				}).then(res => {
					if (res.code == 0) {
						this.orderId = res.data;
						this.payOrderWeb()
					} else {
						this.showMessage(res)
					}
				})
			},
			//验证支付成功未成功按钮
			getOrderStatus() {
				getOrderStatus({
					orderId: this.orderId,
				}).then(res => {
					if (res.code == 0) {
						if (res.data == 0) {
							this.$message({
								type: 'warning',
								message: this.isChinese() ? '支付未成功' : 'Payment failed'
							});
						} else {
							this.payDialogVisible = false;
							this.$message({
								type: 'success',
								message: this.isChinese() ? '支付成功' : 'Payment success'
							});
							this.$router.push('/mine/orderList')
						}
					}
				})
			},
			topay() { //支付方式
				const data = {
					email: '11@qq.com',
					amount: (this.totalPic * 100).toFixed(0),
					reference: this.orderId,
					out_trade_no: '2222',
					mobile_money: {
						phone: "0551234987",
						provider: "mtn"
					}
				}
				const url = 'https://api.paystack.co/transaction/initialize'
				const headers = new Headers()
				headers.append('Authorization', 'Bearer sk_test_da0db0f457e3eec0c06f2a0fb2cb02fb47bed90b')
				fetch(url, {
						method: 'POST',
						headers: headers,
						body: JSON.stringify(data)
					})
					.then(response => response.json())
					.then(res => {
						console.log(res)
						const paystack = new window.PaystackPop()
						console.log(paystack)
						paystack.resumeTransaction(res.data.access_code)
					})
					.catch(() => {
						// handle error here
					})
				// payOrderWeb({
				//     orderId: this.orderId,
				//     type: this.payType,//1微信 2.支付宝
				// }).then(res => {
				//     if (res.code == 0) {
				//         this.dialogVisible = false
				//         if (this.payType == 1) {
				//             this.payDialogVisible = true;
				//             this.wchatPayPic = base.baseURL + 'api/getQRCode?code_url=' + res.orderString;
				//         } else {
				//             document.write(res.orderString)
				//         }
				//     } else {
				//         this.showMessage(res)
				//     }
				// })
			},
			payOrderWeb() {
				this.dialogVisible = true
			},
			handleClose() {
				this.payDialogVisible = false;
				this.dialogVisible = false
			},
			goBack() {
				this.$router.go(-1)
				console.log('go back');
			},
			updateTotalPic() { //计算价格
				let bigPrice = 0;
				let freight = 0;
				this.shoppingCartList.find((item) => {
					bigPrice += item.price * item.number //freight 运费不知道加不加
					freight += item.freight
					this.totalNumber += item.number
				})
				this.totalPic = bigPrice;
				this.freight = freight;
			}
		}
	}
</script>
<style lang="scss" scoped>
	.submit {
		width: 1200px;
		margin: 0 auto;
		padding: 30px 0px;

		.boxss {
			padding: 20px 28px;
			background: #F9F9F9;
			margin: 20px 0px;
		}

		.adrss {

			.title {
				font-size: 22px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 600;
				color: #333333;
				margin-bottom: 15px;
			}

			.phone,
			.adr {
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #333333;
				line-height: 25px;
			}

			.notAdrss {
				display: flex;
				justify-content: space-between;
				align-items: center;
				font-size: 16px;
				padding: 10px 5px;
			}
		}

		.goods-list {
			.title {
				font-size: 16px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #333333;
				padding: 16px 0px;
				border-bottom: 1px solid #ECECEC;
			}

			.list {
				padding: 20px 0px;
				border-bottom: 1px solid #ECECEC;
				display: flex;
				justify-content: space-between;

				.pic {
					width: 120px;
					height: 120px;
					background: #ECE8F1;
				}

				.productDatil {
					width: 970px;

					.dis {
						display: flex;
						justify-content: space-between;
						align-items: center;
					}

					.name {
						span {
							font-size: 18px;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							color: #333333;
						}

						i {
							font-size: 25px;
						}
					}

					.sku {
						padding: 22px 0px;
						font-size: 16px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #333333;
					}

					.del,
					.sku {
						display: flex;
						justify-content: space-between;
						align-items: center;

						span {
							font-size: 16px;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							color: #AAAAAA;
						}
					}

					.del {
						display: flex;
						justify-content: space-between;
						align-items: center;
					}

					.input-number {
						display: flex;
						justify-content: space-between;
						align-items: center;
						width: 90px;
						height: 30px;
						background: #F2F2F2;
						padding: 0px 5px;

						div {
							font-size: 16px;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							color: #000000;
							line-height: 22px;

						}

						.font {
							font-size: 19px;
							color: #000000;
						}
					}
				}
			}
		}

		.totoy {
			div {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 15px 0px;

				span {
					font-size: 20px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #333333;
				}
			}
		}

		.after {
			display: flex;
			justify-content: flex-end;
			align-items: center;

			.right {
				margin-top: 20px;

				.totle {
					font-size: 22px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #333333;
					text-align: right;
					padding: 20px 0px;
				}

				.button {
					background: #000000;
					padding: 16px 80px;
					font-size: 20px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #FFFFFF;
					border: 1px solid #000000;
					cursor: pointer;
				}

				.button:hover {
					color: #000000;
					background: #FFFFFF;
					transition: all .2s ease .2s;
				}
			}
		}
	}

	.pay {
		.title {
			font-size: 16px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #777777;
			line-height: 22px;
			padding: 15px 0px;
			text-align: center;
		}

		.total {
			font-size: 36px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #333333;
			line-height: 50px;
			text-align: center;
			padding: 15px 0px;
		}

		.type {
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 60px 0px;

			.wx,
			.alipay {
				div:first-child {
					border: 1px solid #777777;
					padding: 5px;

					img {
						width: 128px;
						height: 128px;
					}
				}

				div:last-child {
					margin-top: 17px;
					text-align: center;
					font-size: 16px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #000000;

					img {
						width: 24px;
						height: 24px;
						vertical-align: middle;
						margin-right: 5px;
					}
				}
			}

			.wx {}
		}
	}

	.pay {
		text-align: center;

		.title {
			font-size: 16px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #777777;
			line-height: 22px;
			padding: 15px 0px;
			text-align: center;
		}

		.total {
			font-size: 36px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #333333;
			line-height: 50px;
			text-align: center;
			padding: 15px 0px;
		}

		.type {
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 60px 0px;
			margin-top: 0px;

			.wx,
			.alipay {
				text-align: center;

				div:first-child {
					border: 1px solid #777777;
					padding: 5px;

					img {
						width: 228px;
						height: 228px;
					}
				}

				div:last-child {
					margin-top: 17px;
					text-align: center;
					font-size: 16px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #000000;

					img {
						width: 24px;
						height: 24px;
						vertical-align: middle;
						margin-right: 5px;
					}
				}
			}

			.wx {
				// margin-right: 70px;
			}
		}

	}

	.fabumainboxpay {
		text-align: center;

		.fabumainboxpay-titlef {
			.fabumainboxpay-t {
				font-size: 17px;
				font-weight: 400;
				color: #333333;
			}

			.fabumainboxpay-p {
				font-size: 22px;
				font-weight: 500;
				color: #EF0000;
				margin-top: 10px;
			}
		}

		.fabumainboxpay-pay {
			border-top: 1px solid #EEEEEE;
			margin-top: 20px;
			padding-top: 31px;

			.fabumainboxpay-pay-line {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 27px;

				.wximg {
					width: 27px;
					height: 27px;
					margin-right: 27px;
				}

				div {
					font-size: 13px;
					font-weight: 400;
					color: #333333;

					span {
						font-size: 12px;
						color: #777777;
						margin-left: 13px;
					}
				}

				.chooseimg {
					width: 20px;
					height: 20px;
				}
			}
		}

	}

	.dialog-footer-ggpay {
		.el-button {
			width: 40%;
			padding: 15px;
		}
	}

	.dialog-footer-gg1 {
		.btnbox {
			width: 160px;
			margin: 0 10px;
			height: 45px;
			line-height: 45px;
		}

		.cancelbtn {
			background: #F4F4F4;
			color: #4C4A48;
		}
	}
</style>